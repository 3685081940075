/* eslint-disable prettier/prettier */

let typingTimeout;

export function handleQueryUpdate(query) {
  clearTimeout(typingTimeout);

  typingTimeout = setTimeout(() => {
      localStorage.setItem('searchQuery', query);
      console.log('Searched query:', query);
    
  }, 3000);
}

function getSavedQuery() {
  return localStorage.getItem('searchQuery') || '';
}

function getCurrentToken() {
  return localStorage.getItem('currentToken') || '';
}

export const savedQuery = getSavedQuery();
export const currentToken = getCurrentToken();
